<script setup lang="ts">
import { footer } from './footerStyles'

const theme = useRuntimeConfig().public.theme as keyof typeof footer
const {
  base,
  securityList,
  helpSectionDesktop,
  helpSectionDesktopWrapper,
  securityItem,
  securityListWrapper,
  text,
  helpSectionTitle,
  helpSectionText,
  servicesTitle,
} = footer[theme]()

const configStore = useConfigStore()
</script>

<template>
  <div :Class="helpSectionDesktop()">
    <div :class="helpSectionDesktopWrapper()">
      <h3 :class="helpSectionTitle()">{{ $t('cp.footer.contact.title') }}</h3>
      <p :class="helpSectionText()">{{ $t('cp.footer.contact.subtitle') }}</p>
    </div>
    <CPButton
      v-if="configStore.footer.length && configStore.footer[0].url"
      size="sm"
      color="alternative"
      :to="whitelabelNavItem(configStore.footer[0])"
      target="_blank"
      >{{ $t('cp.footer.contact.button-text') }}
    </CPButton>
  </div>
  <div :class="base()">
    <h4 :class="servicesTitle()">
      {{ $t('footer.customer-services.title') }}
    </h4>
    <div v-if="configStore.footer.length" class="w-full">
      <CPPageFooterLinkList />
      <CPDivider class="mt-8 mb-4" />
    </div>

    <div :class="securityListWrapper()">
      <div
        v-if="configStore.configs.safetyIcons.footer.trust"
        :class="securityList()"
      >
        <div :class="securityItem()">
          <CPSafetyIcon
            :image="configStore.configs.safetyIcons.footer.trust"
            :alt="configStore.configs.safetyIcons.footer.trust"
          />
        </div>
        <span :class="text()"> {{ $t('footer.safety-icons.covered') }}</span>
      </div>

      <div
        v-if="configStore.configs.safetyIcons.footer.security.length"
        :class="securityList()"
      >
        <div :class="securityItem()">
          <CPSafetyIcon
            v-for="(item, index) in configStore.configs.safetyIcons.footer
              .security"
            :key="index"
            :image="item"
            :alt="item"
          />
        </div>
        <span :class="text()"> {{ $t('footer.safety-icons.covered') }}</span>
      </div>

      <div
        v-if="configStore.configs.safetyIcons.footer.center"
        :class="securityList()"
      >
        <div :class="securityItem()">
          <CPSafetyIcon
            :image="configStore.configs.safetyIcons.footer.center"
            :alt="configStore.configs.safetyIcons.footer.center"
          />
        </div>
        <span :class="text()">{{ $t('footer.safety-icons.security') }}</span>
      </div>

      <div
        v-if="configStore.configs.safetyIcons.footer.payment.length"
        :class="securityList()"
      >
        <div :class="securityItem()">
          <CPSafetyIcon
            v-for="(item, index) in configStore.configs.safetyIcons.footer
              .payment"
            :key="index"
            :image="item"
            :alt="item"
          />
        </div>
        <span :class="text()">
          {{ $t('footer.safety-icons.secure-payment') }}</span
        >
      </div>
    </div>
  </div>
</template>
