<script setup lang="ts">
import { content } from './contentStyles'

const theme = useRuntimeConfig().public.theme as keyof typeof content

const base = content[theme]
</script>

<template>
  <main :class="base()">
    <div class="cp-container-xl">
      <slot />
    </div>
  </main>
</template>
