import { tv } from 'tailwind-variants'

export const content = {
  alpha: tv({
    base: 'bg-brand-secondary-100 py-4 xl:py-10',
  }),
  bravo: tv({
    base: 'bg-brand-secondary-100 py-4 xl:py-10',
  }),
  charlie: tv({
    base: 'bg-brand-secondary-100 py-4 xl:py-10',
  }),
}
