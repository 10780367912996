import { tv } from 'tailwind-variants'

export const footer = {
  alpha: tv({
    base: 'flex flex-col gap-y-1 items-center',
  }),
  bravo: tv({
    base: 'flex flex-col gap-y-1 items-center',
  }),
  charlie: tv({
    base: 'flex flex-col gap-y-1 items-center',
  }),
}
