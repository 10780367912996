<script setup lang="ts">
import { footer } from './linkListStyles'

const theme = useRuntimeConfig().public.theme as keyof typeof footer
const base = footer[theme]

const ui = base()

const configStore = useConfigStore()
</script>

<template>
  <div :class="ui">
    <CPLink
      v-for="link in configStore.footer"
      :key="link.text"
      size="sm"
      :to="link.url && whitelabelNavItem(link)"
      :target="link.openInNewTab ? '_blank' : '_self'"
      :external="link.isExternal"
    >
      {{ link.text }}
    </CPLink>
  </div>
</template>
