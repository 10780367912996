import { tv } from 'tailwind-variants'

export const footer = {
  alpha: tv({
    base: 'cp-container-xl pt-11 pb-24 flex flex-col gap-y-8 items-center text-center',
    slots: {
      helpSectionDesktop:
        ' bg-brand-secondary-100 flex flex-col px-3  gap-y-4 py-10 xl:py-14 items-center text-center',
      helpSectionDesktopWrapper: 'flex flex-col gap-2 ',
      securityListWrapper:
        'w-full flex flex-col gap-y-8 items-center xl:flex-row xl:justify-evenly',
      securityList: 'flex flex-col gap-2 xl:gap-4',
      securityItem:
        'flex flex-col xl:flex-row gap-2 items-center justify-center xl:min-w-[370px]',
      text: 'max-w-60  self-center',
      helpSectionTitle: '',
      helpSectionText: '',
      servicesTitle: '',
    },
  }),
  bravo: tv({
    base: 'cp-container-xl pt-11 pb-24 flex flex-col gap-y-8 items-center text-center',
    slots: {
      helpSectionDesktop:
        ' bg-brand-secondary-100 flex flex-col px-3  gap-y-4 py-10 xl:py-14 items-center text-center',
      helpSectionDesktopWrapper: 'flex flex-col gap-2',
      securityListWrapper:
        'w-full flex flex-col gap-y-8 items-center xl:flex-row xl:justify-evenly',
      securityList: 'flex flex-col gap-2 xl:gap-4',
      securityItem:
        'flex flex-col xl:flex-row gap-2 items-center justify-center xl:min-w-[370px]',
      text: 'max-w-60  self-center',
      helpSectionTitle:
        'font-heading text-[18px] font-bold text-brand-secondary-800',
      helpSectionText: 'text-[18px] font-light',
      servicesTitle:
        'font-heading text-[16px] font-bold text-brand-secondary-800',
    },
  }),
  charlie: tv({
    base: 'cp-container-xl pt-11 pb-24 flex flex-col gap-y-8 items-center text-center',
    slots: {
      helpSectionDesktop:
        ' bg-brand-secondary-100 flex flex-col px-3  gap-y-4 py-10 xl:py-14 items-center text-center',
      helpSectionDesktopWrapper: 'flex flex-col gap-2 ',
      securityListWrapper:
        'w-full flex flex-col gap-y-8 items-center xl:flex-row xl:justify-evenly',
      securityList: 'flex flex-col gap-2 xl:gap-4',
      securityItem:
        'flex flex-col xl:flex-row gap-2 items-center justify-center xl:min-w-[370px]',
      text: 'max-w-60 self-center',
      helpSectionTitle: '',
      helpSectionText: '',
      servicesTitle: 'font-h4',
    },
  }),
}
